import {useEffect, useState} from "react";
import axios from "axios";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend);

const client = axios.create({
    baseURL: 'https://api.vigilbot.com'
});
const segmentLabels = {
    1: 'Запустил бота',
    2: 'Запустил триал',
    3: 'Платная версия'
}
const memberColors = [
    'rgb(220, 80, 80)', 'rgb(80, 80, 220)', 'rgb(80, 220, 80)'
]
const getTopics = async () => {
    const response = await client.get('/rest/topics/');

    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            resolve(response.data);
        } else {
            reject(response)
        }
    });
}
const groupByDates = membersList => {
    let result = {};
    membersList.forEach(member => {
        const date = moment.unix(member.created_at).format('DD.MM');
        result[date] = result[date] || [];
        result[date].push(member.segment);
    });

    result = Object.entries(result).sort(
        (a, b) => {
            if (a[0] > b[0]) {
                return 1;
            }
            if (a[0] < b[0]) {
                return -1;
            }
            return 0;
        });
    return result
};
const groupBySegments = (groupedByDates) => {
    let result = {}
    groupedByDates.forEach((dateEntry) => {
        result[dateEntry[0]] = {
            1: dateEntry[1].filter(x => x === 1).length,
            2: dateEntry[1].filter(x => x === 2).length,
            3: dateEntry[1].filter(x => x === 3).length,
        }
    });
    return result;
}
const getDatasets = (groupedBySegments) => {
    let result = {
        1: {},
        2: {},
        3: {}
    }
    Object.entries(groupedBySegments).forEach((gbsEntry) => {
        if (gbsEntry[0] !== '17.08') {
            result[1][gbsEntry[0]] = gbsEntry[1][1];
            result[2][gbsEntry[0]] = gbsEntry[1][2];
            result[3][gbsEntry[0]] = gbsEntry[1][3];
        }
    });
    console.log(result);
    return result;
}
const getStatistics = async (topicId) => {
    const response = await client.get(`/rest/topics/${topicId}/statistics/`);
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            resolve(response.data);
        } else {
            reject(response)
        }
    });
}
const getBots = async () => {
    const response = await client.get(`/rest/bots/`);
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            resolve(response.data);
        } else {
            reject(response)
        }
    });
}
const getBotMembers = async (botId) => {
    const response = await client.get(`/rest/bots/${botId}/chats/`);

    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            resolve(response.data);
        } else {
            reject(response)
        }
    });
}
const groupStatistics = (oldStatistics, step) => {
    let newStatistics = [];
    oldStatistics.forEach((entry, index) => {
        if (index % step === 0) {
            newStatistics.push({
                created_at: entry.created_at,
                deleted_messages: oldStatistics
                    .slice(index, index + step)
                    .map(entry => entry['deleted_messages'])
                    .reduce((total, item) => total + item)
            });
        }
    });
    return newStatistics;
};
const periods = ['day', 'week', 'month', 'year']
const App = () => {
    const [topics, setTopics] = useState([]);
    const [selectedTopic, selectTopic] = useState(null);
    const [statistics, setStatistics] = useState([]);
    const [selectedPeriod, setPeriod] = useState('day')
    const [botMembers, setBotMembers] = useState({})

    useEffect(() => {
        getTopics().then(topics => {
            setTopics(topics);
            selectTopic(topics[0])
        })
        getBots().then(bots => {
            bots.forEach(bot => getBotMembers(bot.id).then(newBotMembers => {
                setBotMembers(oldBotMembers => ({
                    ...oldBotMembers,
                    [bot.name]: newBotMembers
                }));
            }));
        })
    }, []);

    useEffect(() => {
        if (selectedTopic != null) {
            getStatistics(selectedTopic.id).then(statistics => setStatistics(statistics));
        }
    }, [selectedTopic]);
    let statisticsToDisplay = statistics;
    let dateFormat = '';
    if (selectedPeriod === 'day') {
        dateFormat = 'HH:mm';
    } else {
        dateFormat = "DD.MM.YYYY";
        statisticsToDisplay = groupStatistics(statistics, 24);
    }
    switch (selectedPeriod) {
        case 'day':
            statisticsToDisplay = statisticsToDisplay.slice(-24);
            break;
        case 'week':
            statisticsToDisplay = statisticsToDisplay.slice(-7);
            break;
        case 'month':
            statisticsToDisplay = statisticsToDisplay.slice(-30);
            break;
        case 'year':
            statisticsToDisplay = statisticsToDisplay.slice(-365);
            break;
        default:
            break;
    }
    const messagesCountLabels = statisticsToDisplay.map(entry => moment.unix(entry.created_at).add(-12, 'hours').format(dateFormat));

    return (<div>
            <div id="page">
                <div id="periods">{periods.map(period => <div
                    className={'periodButton' + (selectedPeriod.id === period.id ? ' selected' : '')}>
                    <a href='#' onClick={e => setPeriod(period)}>{period}</a>
                </div>)}</div>
                <br/>
                <br/>
                <div id="topics">{topics.map(topic => <div
                    className={'topicButton' + (selectedTopic.id === topic.id ? ' selected' : '')}>
                    <a href='#' onClick={e => selectTopic(topic)}>{topic.name}</a>
                </div>)}</div>
                <div id="statistics">
                    {selectedTopic === null ? null : <Line style={{'width': '100%'}} data={{
                        labels: messagesCountLabels,
                        datasets: [{
                            label: selectedTopic.name,
                            data: statisticsToDisplay.map(entry => entry.deleted_messages),
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        }]
                    }}/>}
                </div>
            </div>
            {
                Object.entries(botMembers).map(([botName, chats]) =>
                    <div className="members-list">
                        <h3>{botName}</h3>
                        <Line style={{'width': '100%'}} data={{
                            labels: Object.keys(getDatasets(groupBySegments(groupByDates(chats)))[1]).map(timestamp => timestamp),
                            datasets: Object.entries(getDatasets(groupBySegments(groupByDates(chats)))).map(
                                entry => {
                                    return {
                                        label: segmentLabels[entry[0]],
                                        data: entry[1],
                                        borderColor: memberColors[entry[0] - 1],
                                        backgroundColor: memberColors[entry[0] - 1]
                                    }
                                })
                        }}/>
                    </div>)
            }
        </div>
    );
}

export default App;
